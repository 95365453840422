// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDYDNIfsehqKnZPKgj6KALD9832fVfLFLM",
  authDomain: "wisycode.firebaseapp.com",
  projectId: "wisycode",
  storageBucket: "wisycode.firebasestorage.app",
  messagingSenderId: "863754424057",
  appId: "1:863754424057:web:82df1851a685e6b8d4dc28"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);