/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { app } from './firebase/firebase.config'; // Import initialized Firebase app
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const auth = getAuth(app);
const functions = getFunctions(app);

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  console.log(loading)
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.subject) newErrors.subject = "Subject is required.";
    if (!formData.message) newErrors.message = "Message is required.";
    return newErrors;
  };

  const callFunctionWithToken = (token) => {
    setLoading(true); // Start loading
    fetch('https://email-wizy.vercel.app/wisyCoderMail', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Function response:', data);
        toast.success("Mail sent successfully!");
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
      })
      .catch(error => {
        console.error('Error calling function:', error);
        toast.error("Failed to send mail.");
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const email = 'wisycoder@gmail.com';
    const password = 'Torontobd07#';

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return; // Stop the execution if there are errors
    } else {
      setErrors({}); // Clear errors if there are none
    }

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log('Signed in successfully:', userCredential);
        return userCredential.user.getIdToken();
      })
      .then((idToken) => {
        callFunctionWithToken(idToken);
      })
      .catch((error) => {
        // If sign-in fails, attempt to create the user
        if (error.code === 'auth/user-not-found') {
          createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              console.log('User created successfully:', userCredential);
              return userCredential.user.getIdToken();
            })
            .then((idToken) => {
              callFunctionWithToken(idToken);
            })
            .catch((creationError) => {
              console.error('Error creating user:', creationError.code, creationError.message);
            });
        } else {
          console.error('Error signing in:', error.code, error.message);
        }
      });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div className="App">
      <>
        <header className="header">
          <div className="container">
            <div className="site-header clearfix">
              <div className="col-lg-3 col-md-3 col-sm-12 title-area">
                <div className="site-title" id="title">
                  <a href="index.html" title="">
                    <h4>Wisy<span>Code</span></h4>
                  </a>
                </div>
              </div>

              <div className="col-lg-9 col-md-12 col-sm-12">
                <div id="nav" className="right">
                  <button className="menu-toggle" onClick={toggleMenu}>
                    ☰
                  </button>
                  <div className={`container clearfix ${isMenuOpen ? 'open' : ''}`}>
                    <ul id="jetmenu" className="jetmenu blue">
                      <li className="active"><a href="index.html">Home</a></li>
                      <li><a href="#section_about_us">About Us</a></li>
                      <li><a href="#section_services">Services</a></li>
                      <li><a href="#section_products">Portfolio</a></li>
                      <li><a href="#section_price">Prices</a></li>
                      <li><a href="#section_contact">Contact</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <section id="intro">
          <div className="overlay-box">
            <h1 style={{ color: '#FF7F50' }}>Custom Website Design & Development!</h1>
            <p>Your Gateway to a Stunning Online Presence</p>
          </div>
        </section>




        <section className="section1 text-center" id="section_services">
          <div className="container">
            <div className="general-title"></div>
            <h3>Our Services</h3>
            <hr />
          </div>
          <div className="row">
            {/* Web Design & Development */}
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="servicebox text-center">
                <div className="service-icon" data-aos="fade-up">
                  <div className="dm-icon-effect-1" data-effect="slide-left" data-aos="fade-right">
                    <a href="#" className="">
                      <i className="active dm-icon fa fa-bars fa-3x"></i>
                    </a>
                  </div>
                  <div className="servicetitle">
                    <h4>Web Design & Development</h4>
                    <hr />
                  </div>
                  <p>
                    From concept to launch, we deliver full-service web design and development
                    to help your business. Get a beautifully designed website customized to your needs.
                  </p>
                </div>
              </div>
            </div>

            {/* SEO */}
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="servicebox text-center">
                <div className="service-icon" data-aos="fade-up">
                  <div className="dm-icon-effect-1" data-effect="slide-bottom" data-aos="fade-up">
                    <a href="#" className="">
                      <i className="active dm-icon fa fa-laptop fa-3x"></i>
                    </a>
                  </div>
                  <div className="servicetitle">
                    <h4>SEO</h4>
                    <hr />
                  </div>
                  <p>
                    Get a professionally designed website that’s optimized for performance, SEO, and user experience.
                  </p>
                </div>
              </div>
            </div>

            {/* Maintenance */}
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="servicebox text-center">
                <div className="service-icon" data-aos="fade-up">
                  <div className="dm-icon-effect-1" data-effect="slide-bottom" data-aos="fade-up">
                    <a href="#" className="">
                      <i className="active dm-icon fa fa-book fa-3x"></i>
                    </a>
                  </div>
                  <div className="servicetitle">
                    <h4>Maintenance</h4>
                    <hr />
                  </div>
                  <p>
                    Focus on your business while we handle the technical side,
                    from bug fixes to content updates, ensuring your site remains secure and efficient.
                  </p>
                </div>
              </div>
            </div>

            {/* Consultation */}
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div className="servicebox text-center">
                <div className="service-icon" data-aos="fade-up">
                  <div className="dm-icon-effect-1" data-effect="slide-right" data-aos="fade-left">
                    <a href="#" className="">
                      <i className="active dm-icon fa fa-book fa-3x"></i>
                    </a>
                  </div>
                  <div className="servicetitle">
                    <h4>Consultation</h4>
                    <hr />
                  </div>
                  <p>
                    Whether you're launching a new project or optimizing an existing one,
                    our expert consultants are here to guide you every step of the way.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="section5" id="section_about_us">
          <div className="container">
            <div className="col-lg-6 col-md-6 col-sm-12 columns">
              <div className="widget" data-aos="fade-right">
                <img src="img/slider_02.png" alt="" />
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 columns text-start">
              <div className="widget clearfix" data-aos="fade-left">
                <div>
                  <h2 className="big-title" style={{ color: '#ffffff' }}>About Us</h2>
                  <hr />
                </div>
                <p>
                  WisyCode Inc. is a growing software development company based in Toronto,
                  specializing in custom solutions for web, enterprise, and mobile applications.
                  With 15 years of experience, we blend proven methodologies, deep industry knowledge, and
                  technical expertise to deliver high-quality software solutions.
                </p>

                <p>
                  Combining technical expertise with a deep understanding of industry challenges,
                  WisyCode Inc. delivers custom software solutions that empower businesses to grow and
                  thrive.<br />
                </p>
                <p>
                  - Meet us in-person or schedule call - 24/7 support <br />
                  - Don’t pay until you satisfied - unlimited revisioning <br />
                  - Mobile responsive and user friendly <br />
                </p>
              </div>
            </div>
          </div>
        </section>


        <section className="section1 text-center" id="section_price">
          <div className="container">
            <div className="general-title">
              <h3>AFFORDABLE PRICES</h3>
              <hr />
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up">
                <div className="custom-box">
                  <div className="servicetitle">
                    <h4>One Stop</h4>
                    <hr />
                  </div>
                  <div className="icn-main-container">
                    <span className="icn-container">$399</span>
                  </div>
                  <p>
                    Your business deserves a website that combines innovative design with seamless functionality,
                    ensuring a user-friendly experience.
                  </p>
                  <ul className="pricing">
                    <li>Single page website design</li>
                    <li>No surprise costs; your package includes logo, images, design, domain, and hosting!</li>
                    <li>Ownership rights</li>
                    <li>24/7 Support for 1 year</li>
                  </ul>
                  <br/>
                  <a className="btn btn-primary" href="#section_contact">Contact Now</a>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up">
                <div className="custom-box">
                  <div className="servicetitle">
                    <h4>Monthly</h4>
                    <hr />
                  </div>
                  <div className="icn-main-container">
                    <span className="icn-container">$38.0</span>
                  </div>
                  <p>
                    Start your journey with zero upfront costs. With a simple monthly minimum payment,
                    you can focus on what matters most—your business!
                  </p>
                  <ul className="pricing">
                    <li>Single page website design</li>
                    <li>No surprise costs; your package includes logo, images, design, domain, and hosting!</li>
                    <li>Cancel anytime</li>
                    <li>Unlimited maintenance services!</li>
                    <li>24/7 Support</li>
                  </ul>
                  <a className="btn btn-primary" href="#section_contact">Contact Now</a>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up">
                <div className="custom-box">
                  <div className="servicetitle">
                    <h4>Others</h4>
                    <hr />
                  </div>

                  <p>
                    Looking for something more specialized? We offer a range of ad hoc packages available upon request.
                    <br />Get in touch with us to learn more about the options that best fit your project.
                  </p>
                  <ul className="pricing">
                    <li>Free consultation and assessment</li>
                    <li>We offer the lowest prices without compromising on quality!</li>
                    <li>No matter how complex your project may be, trust our experts to demonstrate their exceptional expertise. Give us the opportunity to prove it!</li>
                  </ul>
                  <a className="btn btn-primary" href="#section_contact">Contact Now</a>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="section5 text-center" id="section_products">
          <div className="message text-center">
            <h2 className="big-title">
              Our <span>Best</span> Products
            </h2>
          </div>
          <div className="portfolio-wrapper">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              nav
              items={3}
              autoplay
            >
              <div className="item">
                <a href="https://impexinfinity.ca">
                  <img
                    className="lazyOwl"
                    src="img/portfolio_01.jpg"
                    data-src="img/portfolio_01.jpg"
                    alt=""
                  />
                  <div>
                    <small>Trading</small>
                    <span>ImpexInfinity</span>
                    <i className="fa fa-link"></i>
                  </div>
                </a>
              </div>

              <div className="item">
                <a href="https://fivestarsmeat.com/">
                  <img
                    className="lazyOwl"
                    src="img/portfolio_05.jpg"
                    data-src="img/portfolio_05.jpg"
                    alt=""
                  />
                  <div>
                    <small>Meat Warehouse</small>
                    <span>FiveStarsMeat</span>
                    <i className="fa fa-link"></i>
                  </div>
                </a>
              </div>

              <div className="item">
                <a data-rel="prettyPhoto" href="img/portfolio_09.jpg">
                  <img
                    className="lazyOwl"
                    src="img/portfolio_09.jpg"
                    data-src="img/portfolio_09.jpg"
                    alt=""
                  />
                </a>
              </div>

              <div className="item">
                <a href="https://play.google.com/store/apps/details?id=com.expensecount.groupExpense&hl=en">
                  <img
                    className="lazyOwl"
                    src="img/portfolio_10.jpg"
                    data-src="img/portfolio_10.jpg"
                    alt=""
                  />
                  <div>
                    <small>Mobile App</small>
                    <span>GroupXpense</span>
                    <i className="fa fa-link"></i>
                  </div>
                </a>
              </div>

              <div className="item">
                <a data-rel="prettyPhoto" href="img/portfolio_06.jpg">
                  <img
                    className="lazyOwl"
                    src="img/portfolio_06.jpg"
                    data-src="img/portfolio_06.jpg"
                    alt=""
                  />
                </a>
              </div>
            </OwlCarousel>
          </div>
        </section>

        <section className="section1" id="section_contact">
          <div className="container clearfix">
            <div className="content col-lg-12 col-md-12 col-sm-12 clearfix">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <h4 className="title">Contact Form</h4>
                <form
                  className="contact-form php-mail-form"
                  onSubmit={sendEmail}
                  method="POST"
                >
                  <div className="form-group">
                    <input
                      type="name"
                      name="name"
                      className="form-control"
                      id="contact-name"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && <p style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{errors.name}</p>}

                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="contact-email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{errors.email}</p>}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      id="contact-subject"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                    />
                    {errors.subject && <p style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{errors.subject}</p>}
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      id="contact-message"
                      placeholder="Your Message"
                      rows="5"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    {errors.message && <p style={{ color: 'red', fontSize: '10px', textAlign: 'left' }}>{errors.message}</p>}
                  </div>

                  <div className="form-send">
                    <button type="submit" className="btn btn-large btn-primary" disabled={loading}>
                      {loading ? "Sending Message ..." : "Send Message"}
                    </button>

                  </div>
                </form>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <h4 className="title">Contact Details</h4>
                <p>
                  WisyCode Inc. is a growing software development company based in Toronto,
                  specializing in custom solutions for web, enterprise, and mobile applications.
                  With 15 years of experience, we blend proven methodologies, deep industry knowledge, and
                  technical expertise to deliver high-quality software solutions.
                </p>
                <br />
                <ul className="contact_details">
                  <li><i className="fa fa-envelope-o"></i> info@wisycode.com</li>
                  <li><a href="tel:+14163196587">
                    <i className="fa fa-phone-square"></i> +1-416-319-6587</a>
                  </li>
                  <li>
                    <i className="fa fa-home"></i> 646 Antigua Crescent, Oshawa, ON L1J 6B4 Canada
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ToastContainer />
        </section>

        <footer className="footer">
          <div className="container">
            <div className="widget col-lg-4 col-md-4 col-sm-12">
              <h4 className="title">About us</h4>
              <p>
                <span style={{ color: '#dbdbe8ca' }}>WisyCode Inc. </span><br></br>
                Your Gateway to a Stunning Online Presence.
              </p>
            </div>


            <div className="widget col-lg-4 col-md-4 col-sm-12">
              <h4 className="title">Quick Links</h4>
              <ul className="contact_details">
                <li><a href="#section_about_us">About Us</a></li>
                <li><a href="#section_services">Services</a></li>
                <li><a href="#section_products">Portfolio</a></li>
              </ul>
            </div>


            <div className="widget col-lg-4 col-md-4 col-sm-12">
              <h4 className="title">Get In Touch</h4>
              <ul className="contact_details">
                <li><i className="fa fa-envelope-o"></i> info@wisycode.com</li>
                <li><a href="tel:+14163196587">
                    <i className="fa fa-phone-square"></i> +1-416-319-6587</a>
                </li>
                <li>
                  <i className="fa fa-home"></i> 646 Antigua Crescent, Oshawa, ON L1J 6B4
                </li>
              </ul>
            </div>
          </div>


          <div className="copyrights">
            <div className="container">
              <div >
                <p style={{ textAlign: 'center' }}>Copyright © 2014 - All rights reserved by WisyCode Inc.</p>
              </div>

            </div>
          </div>
        </footer>
        {showScrollTop && (
          <div className="dmtop" onClick={scrollToTop}>
            Scroll to Top
          </div>
        )}
      </>
    </div>
  );
}

export default App;